<template>
  <dialog-form-list
    :config="config"
    :formData="formData"
    @cancel="cancel"
    @confirm="confirm"
  />
</template>

<script>
export default {
  props: {
    tableRow: {
      // 当前选中的表格行数据
      type: Object,
      default: () => null,
    },
    language: {
      // 语言类型
      type: String,
      default: '',
    },
    dictType: {
      // 字典类型
      type: String,
      default: '',
    },
  },
  data() {
    return {
      cmd: this.$store.state.cmd,
      formData: {
        language: this.language,
        dictType: this.dictType,
      },
      config: [
        // lang:名称
        {
          label: this.$t('dictManage.value'),
          prop: 'value',
          rules: {
            noNull: true,
          },
        },
        // lang:语言
        {
          tag: 'el-select',
          label: this.$t('dictManage.language'),
          prop: 'language',
          tagProps: {
            disabled: true,
            options: this.$dict.language(),
          },
        },
        // lang:类型
        {
          tag: 'el-select',
          label: this.$t('dictManage.dictType'),
          prop: 'dictType',
          tagProps: {
            disabled: true,
            options: this.$dict.dictTypeEnum(),
          },
        },
        // lang:备注
        {
          label: this.$t('base.notes'),
          prop: 'notes',
          tagProps: {
            type: 'textarea',
            rows: 4,
          },
        },
      ],
    }
  },
  mounted() {
    this.setDefaultValue()
  },
  methods: {
    // 设置默认值
    setDefaultValue() {
      this.$nextTick(() => {
        //默认值
        if (this.cmd !== 'add') {
          this.$util.setDefaultValue(this.formData, this.tableRow)
        }
      })
    },
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
    // 确定
    confirm() {
      const formData = this.$util.copyData(this.formData)
      switch (this.cmd) {
        case 'add':
          this.addMethod(formData)
          break
        case 'edit':
          this.editMethod(formData)
          break
        default: // do something
      }
    },
    // 新增
    addMethod(formData) {
      if (this.tableRow) formData.psn = this.tableRow.sn
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.dictAdd,
        data: formData,
      }).then(() => {
        // lang:新增成功
        this.$element.showMsg(this.$t('base.addSuccess'), 'success')
        this.$emit('closePage', 'add')
      })
    },
    // 修改
    editMethod(formData) {
      const tableRow = this.$util.copyData(this.tableRow)
      Object.assign(tableRow, formData)
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.dictUpdate,
        data: tableRow,
      }).then(() => {
        // lang:修改成功
        this.$element.showMsg(this.$t('base.editSuccess'), 'success')
        this.$emit('closePage', 'edit')
      })
    },
  },
}
</script>