<template>
  <both-colum-page :title="tableTitle" @functionMethod="functionMethod">
    <!-- lang:字典类型 -->
    <template v-slot:left-title>{{ $t('dictManage.dictType') }}</template>
    <template v-slot:left>
      <div>
        <el-select
          style="width: 100%"
          v-model="params.language"
          @change="selectLanguage"
        >
          <el-option
            v-for="item in languageList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
      <ul style="margin-top: 10px">
        <li v-for="item in dictTypeEnum" :key="item.value">
          <el-button
            style="width: 100%; margin-top: 20px"
            :type="item.value === params.dictType ? 'primary' : ''"
            :icon="item.icon"
            @click="selectDictType(item)"
          >
            {{ item.label }}
          </el-button>
        </li>
      </ul>
    </template>
    <table-list
      ref="tableList"
      :url="'/' + $util.getLocalStorage('apiprefix') + $api.dictFindTree"
      :defaultParams="params"
      :pagination="false"
      :columns="columns"
      :treeProps="{ children: 'children' }"
      v-model="tableRow"
    >
      <!-- 创建时间 -->
      <template v-slot:createTime="{ row }">
        {{ $util.format(row.createTime, 'yyyy-MM-dd') }}
      </template>
      <!-- 语言-->
      <template v-slot:language="{ row }">
        {{ languageListObj[row.language] }}
      </template>
      <!-- 类型-->
      <template v-slot:dictType="{ row }">
        {{ dictTypeEnumObj[row.dictType] }}
      </template>
    </table-list>
    <detail
      v-if="showDetail"
      :tableRow="tableRow"
      :language="params.language"
      :dictType="params.dictType"
      @closePage="closePage"
    />
  </both-colum-page>
</template>

<script>
import Detail from './detail'

export default {
  components: {
    Detail,
  },
  data() {
    const languageList = this.$dict.language()
    let dictTypeEnum = ''
    let dictTypeLang = ''
    if (this.$util.getLocalStorage('apiprefix') === 'tmva') {
      dictTypeEnum = this.$dict.dictTypeEnumTMVA()
      dictTypeLang = 'DOCUMENT_TYPE'
    } else if (this.$util.getLocalStorage('apiprefix') === 'core'){
      dictTypeEnum = this.$dict.dictTypeEnumCORE()
      dictTypeLang = 'COMPANY_INDUSTRY'
    } else {
      dictTypeEnum = this.$dict.dictTypeEnum()
      dictTypeLang = 'COMPANY_INDUSTRY'
    }
    return {
      // 左边行业类型
      languageList: languageList, // 语言类型
      languageListObj: this.$util.listToObj(languageList),
      dictTypeEnum: dictTypeEnum, // 字典类型
      dictTypeEnumObj: this.$util.listToObj(dictTypeEnum),
      params: {
        language: 'zhCN',
        dictType: dictTypeLang,
      },
      // 表格页
      columns: [
        // lang:名称
        {
          label: this.$t('dictManage.value'),
          prop: 'value',
        },
        // lang:创建时间
        {
          label: this.$t('base.createTime'),
          prop: 'createTime',
        },
        // lang:语言
        {
          label: this.$t('dictManage.language'),
          prop: 'language',
        },
        // lang:类型
        {
          label: this.$t('dictManage.dictType'),
          prop: 'dictType',
        },
        // lang:备注
        {
          label: this.$t('base.notes'),
          prop: 'notes',
        },
      ],
      tableRow: null,
      // 表单页
      showDetail: false,
    }
  },
  computed: {
    tableTitle() {
      const { dictType, language } = this.params
      return (
        this.dictTypeEnumObj[dictType] + '-' + this.languageListObj[language]
      )
    },
  },
  methods: {
    // 关闭详情页回调
    closePage(method) {
      this.showDetail = false
      if (method && method !== 'cancel') {
        this.search()
      }
    },
    // 选择语言
    selectLanguage() {
      this.search()
    },
    // 选择字典类型
    selectDictType(item) {
      this.params.dictType = item.value
      this.search()
    },
    // 查询按钮点击回调
    search(isActiveQuery) {
      this.$refs.tableList.searchList(this.formData, isActiveQuery)
    },
    // 权限按钮点击回掉
    functionMethod(item) {
      console.log(item)
      const { method } = item
      if (method !== 'add' && !this.tableRow) {
        this.$element.showMsg(this.$t('base.selectTableRow'), 'warning')
        return
      }
      if (
        method === 'add' &&
        this.tableRow &&
        this.params.dictType === 'VERSION_CATEGORY'
      ) {
        this.$element.showMsg(this.$t('base.canAddFirstCate'), 'warning')
        return
      }
      switch (method) {
        case 'add':
        case 'edit':
        case 'view':
          this.showDetail = true
          break
        case 'del':
          this.deleteMethod()
          break
        default: // do something
      }
    },
    // 删除
    deleteMethod() {
      this.$element
        .showConfirm({
          content: this.$t('base.deleteConfirm'),
        })
        .then(() => {
          this.$ajax({
            url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.dictDelete,
            data: this.tableRow,
          }).then(() => {
            // lang:删除成功
            this.$element.showMsg(this.$t('base.deleteSuccess'), 'success')
            this.search()
          })
        })
    },
  },
}
</script>
